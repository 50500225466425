<template>
  <div class="search">
    <div class="search-main">
      <van-icon name="search" color="#fff" size="16" />
      <input
        type="text"
        v-model="value"
        class="input"
        placeholder="请输入关键词"
      />
      <div class="search-button" @click="search">搜索</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
    };
  },
  methods: {
    search() {
      this.$emit("search", this.value);
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  margin: 0 13px;
  padding-top: 64px;
  .search-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 39px;
    padding-left: 16px;
    background: rgb(253, 148, 153);
    border-radius: 39px;
  }
}
.input {
  flex: 1;
  background-color: transparent;
  outline: none;
  border: none;
  color: #fff;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 18px;
}
.search-button {
  width: 74px;
  height: 34px;
  background: rgb(255, 255, 255);
  border-radius: 34px;
  text-align: center;
  line-height: 34px;
  color: #ed301d;
  font-size: 18px;
  margin-right: 3px;
}
input::-webkit-input-placeholder {
  color: #fff;
}
</style>