<template>
  <div class="goods-page">
    <div class="header">
      <img src="@/assets/goods/bg@2x-bg.png" class="bg-img" />
      <NavBar iconColor="#fff">
        <img
          src="@/assets/goods/_seconds_kill_img@2x.png"
          class="title-img"
          slot="title"
        />
      </NavBar>
      <Search @search="search"></Search>
    </div>
    <GoodsList ref="refGoods"></GoodsList>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import Search from "./modules/search/search.vue";
import GoodsList from "./modules/goods-list/goods-list.vue";
export default {
  name: "new-user",
  components: {
    NavBar,
    Search,
    GoodsList,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    search(value) {
      this.$refs.refGoods.initialization({ goodsName: value });
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="less" scoped>
.goods-page {
  position: relative;
}
.header {
  position: sticky;
  top: 0;
  width: 100%;
  height: 122px;
  z-index: 10;
  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
.title-img {
  width: 104px;
  height: 32px;
}
</style>