<template>
  <van-list
    v-model="loading"
    :finished="finished"
    :immediate-check="false"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <div class="goods-list">
      <div class="unordered-list">
        <div
          class="list"
          v-for="item in list"
          :key="item.id"
          @click="goToDetail(item)"
        >
          <img :src="item.cover_img" class="goods-img" />
          <div class="goods-info">
            <div class="goods-name">
              {{ item.good_name }}
            </div>
            <div class="info-bottom">
              <Price
                class="original-price"
                :price="item.spec_pricing"
                decimalStyle="13"
                spanColor="#b9babe"
                integerStyle="13"
              ></Price>
              <div class="price-label">
                <Price class="price-box" :price="item.price"></Price>
              </div>
              <div class="sell-info">
                <img
                  src="@/assets/common/icon-good-opinion.png"
                  class="score-icon"
                />
                <div class="common">{{ item.score }}分</div>
                <div class="common">|</div>
                <div class="common">{{ item.sales }}人付款</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-list>
</template>

<script>
import { getNewUserGoods } from "@/api/NewUser.js";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      page: 1,
      goodsName: "",
    };
  },
  mounted() {
    this.initialization();
  },
  methods: {
    goToDetail(item) {
      this.$router.push({
        name: "detail",
        query: {
          goodsId: item.id,
          type: 5,
        },
      });
    },
    onLoad() {
      ++this.page;
      // this.getNewUserGoods();
    },
    initialization(data) {
      if (data) {
        this.goodsName = data.goodsName;
      }
      this.list = [];
      this.page = 1;
      // this.getNewUserGoods();
    },
    async getNewUserGoods() {
      const res = await getNewUserGoods({
        page: this.page,
        good_name: this.goodsName,
      });
      if (res.code === 1) {
        this.list = [...this.list, ...res.data.list];
        if (res.data.list.length < 20) {
          this.finished = true;
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.goods-list {
  margin: 10px 13px 0 13px;
}
.unordered-list {
  .list {
    display: flex;
    background-color: #fff;
    border-radius: 13px;
    padding: 12px;
    margin-bottom: 10px;
    .goods-img {
      flex-shrink: 0;
      width: 129px;
      height: 129px;
      border-radius: 6px;
    }
  }
}
.goods-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
  .goods-name {
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-all;
  }
  .original-price {
    font-size: 13px;
    color: #b9babe;
    text-decoration: line-through;
  }

  .price-label {
    position: relative;
    width: 160px;
    height: 31px;
    margin-top: 5px;
    background: url("../../../../../assets/goods/bg2@2x-user.png") no-repeat
      center / 100% 100%;
    .price-box {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 60px;
    }
  }
}
.sell-info {
  display: flex;
  align-items: center;
  margin-top: 5px;
  .score-icon {
    width: 13px;
    height: 13px;
  }
  .common {
    font-size: 13px;
    color: #bbbbbb;
    margin-left: 3px;
  }
}
</style>
